.navItemHover:hover {
    /* text-decoration: underline;
    text-decoration-thickness: 10px; */
    color: black;
    background-color: black;
    border: 1px solid black;
    border-radius: 5px;
}

.logo {    
    background-color: white;
}
.logo:hover {    
    background-color: silver;
}