:link {
    color: white;
}

:visited {
    color: white;
}

.clipboard-btn {
    color: white;
}